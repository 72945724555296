<template>
  <svg
    v-tippy="{ placement: 'right' }"
    class="authentication-helper"
    xmlns="http://www.w3.org/2000/svg"
    height="20px"
    viewBox="0 0 24 24"
    width="20px"
    fill="#000000"
    content="Login is vereist om deze laag te bekijken"
    aria-label="Login is vereist om deze laag te bekijken"
  >
    <g fill="none">
      <path d="M0 0h24v24H0V0z" />
      <path d="M0 0h24v24H0V0z" opacity=".87" />
    </g>
    <path
      d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
    />
  </svg>
</template>

<script>
export default {
  name: "LayerAuthentication",
  components: {},
  props: {
    layer: Object,
  },
};
</script>

<style scoped>
.authentication-helper {
  margin-left: 5px;
}
</style>
